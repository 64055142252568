


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { doc, setDoc, getDoc } from "firebase/firestore"; 
import { useEffect, useState } from "react";
import { MdContentCopy } from "react-icons/md";

import { BrowserRouter, useParams } from 'react-router-dom';
import { people } from "../invidatos";
import { LuHeartHandshake } from "react-icons/lu";
import Renderif from "../utils/renderif";
import CheckBox from "../components/checkbox";
import axios from "axios";
import { FaInstagram } from "react-icons/fa";
import { FaSpotify } from "react-icons/fa";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDruyQl5pqrhNGC-MzjEwY7xdQ8iHLzTeE",
  authDomain: "enbodachados-63d1b.firebaseapp.com",
  projectId: "enbodachados-63d1b",
  storageBucket: "enbodachados-63d1b.appspot.com",
  messagingSenderId: "899270868113",
  appId: "1:899270868113:web:7386c38b17177af7f71330"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);




function Invitacion() {

  const {id} = useParams();
  const [state, setState] = useState({
    invitado:null,
    loading:false,
    copy:false, 
    show_invitados:false,
    qr:'',
    copyText:''
  })
  useEffect(()=>{
    
   const init = async () => {
    const docRef = doc(db, "invitados", `${id}`);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      let _invitado = docSnap.data();
      setState({...state, invitado:_invitado})
    } 
   }
   init();
   return () =>{}
    
    // let _invitado = people.find((item) => item.ui === parseInt(id))
    // console.log(_invitado) 
  
  },[])

  const renderExtras = (id) => {
   let extras = []
   for (let i = 0; i < id; i++) {
    
    extras.push(1)
    
   }
   return extras;
  }
  const renderKids = (id) => {
    let kids = []
    for (let i = 0; i < id; i++) {
     
      kids.push(1)
     
    }
    return kids;
   }
  const onUpdate = async () => {



    try {
      setState({...state, loading:true})
      await setDoc(doc(db, "invitados", `${id}`), {
        ...state.invitado,
      });

      if(state.invitado?.familia?.length > 0){
        for (let i = 0; i < state.invitado?.familia?.length; i++) {

            if(state.invitado.familia[i].attendance){
              await setDoc(doc(db, "invitados", `${state.invitado.familia[i].ui}`), {
                ...state.invitado.familia[i],
              });
            }
          
        }
      }

    
      
      if(
        state?.invitado?.familia?.some((item)=>item?.attendance === true) || 
        state?.invitado?.attendance === true ||
        state?.invitado?.extraAttendance?.length > 0 ||
        state?.invitado?.kidAttendance?.length > 0
        ){
        state.copyText = '¡Te esperamos!'
      }else{
        state.copyText = '¡Te vamos a extrañar!'
      }
    



      setState({...state, loading:false, confirmed:true, })

      setTimeout(()=>{
        state.copyText = ''
        setState({...state, loading:false, confirmed:false})
      },2000)


    } catch (error) {
      console.log(error)
    }


  }
  
  return (  
    
    <>
    <Renderif isTrue={state.loading}>
      <div className="loading">
        <div className="spinner-border text-light"></div>
      </div>
    </Renderif>
    <div className='container max '>

        <div className=' vh-100 d-flex justify-content-center align-items-center'>
            <img width={"100%"} className='d-block mx-auto' src="img/talieva.png"/>
        </div>

        <div className=' d-flex flex-column justify-content-center align-items-center'>
            <img width={"100%"} className='d-block mx-auto' src="img/talieva2.png"/>
            <div className='text-center f-14 pop mt-5'>
              Después de 9 años juntos, ¡no esta mal, eh! <br></br>
              Nos dimos cuenta de que queremos envejecer <br></br>
              juntos, así que hemos decidido dar el Gran Paso <br></br>
              y continuar el camino con los anillos puestos.
            </div>
        </div>

        <div className=' d-flex flex-column justify-content-center align-items-center mt-5'>
            <img width={"100%"} className='d-block mx-auto' src="img/talieva3.png"/>
            <div className='text-center f-14 pop mt-5'>
              Si has recibido esto, es por que para nosotros <br></br>
              eres imprescindible en nuestro día <br></br>
              y queremos compartirlo contigo. <br></br><br></br>
             Queremos verte reír, comer, saltar, bailar y, si te <br></br>
             empeñas, hasta soltar alguna que otra lagrimilla. <br></br><br></br>
             Esperamos contar contigo.
            </div>
        </div>

        <div className='d-flex flex-column justify-content-center align-items-center mt-5 position-relative'>
            <img width={"90%"} className='d-block mx-auto' src="img/talieva5.png"/>
            <div className='map-2 cursor-pointer' onClick={()=>{
              window.open('https://www.google.com/maps/place/Parroquia+de+F%C3%A1tima/@19.6954659,-101.1860612,17z/data=!3m1!4b1!4m6!3m5!1s0x842d0c3284963131:0x3b0d62a96b31101f!8m2!3d19.6954659!4d-101.1834809!16s%2Fg%2F1v3gs6yw?entry=ttu','_blank')
            }}>
               
               <div className="text-center">
                <div className="bolder bebas text-center text-white f-18">20 ABRIL 2024 4:30 PM</div>
                <div className="bolder pop text-white f-12 line-1">PARROQUIA DE FATIMA</div>
                <div className="bolder stix text-white f-12 line-1 mt-3">Calle Isidro Huarte s/n <br></br> Col. Cuauhtemoc. <br></br> Morelia, Mich.</div>
              </div>
              <img className="d-block mx-auto mt-2" width="60px" src="img/pin.png"></img>
              <img className="d-block mx-auto mt-2" width="80px" src="img/comobtn.png"></img>

        
         
            </div>
        </div>

        <div className='d-flex flex-column justify-content-center align-items-center mt-5'>
            <img width={"100%"} className='d-block mx-auto' src="img/horario.png"/>
        </div>

        <div className='d-flex flex-column justify-content-center align-items-center mt-5 position-relative pe-1'>
            <img width={"100%"} className='d-block mx-auto' src="img/talieva6.png"/>
            <div className='map-1 cursor-pointer' onClick={()=>{
              window.open('https://www.google.com/maps/place/La+finca+Garden/@19.6729855,-101.2212238,17z/data=!3m1!4b1!4m6!3m5!1s0x842d0d154629d6ed:0xcb636e0b734d48fe!8m2!3d19.6729855!4d-101.2212238!16s%2Fg%2F11k46q5kxd?entry=ttu','_blank')
            }}>

              <div className="text-center">
                <div className="bolder bebas text-center text-white f-18">20 ABRIL 2024 7:00 PM</div>
                <div className="bolder pop text-white f-14 line-1">JARDIN FINCA</div>
                <div className="bolder stix text-white f-12 line-1 mt-3">Calzada la Huerta 3577 <br></br> Col. Xangari. <br></br> Morelia, Mich.</div>
              </div>
              <img className="d-block mx-auto mt-2" width="60px" src="img/pin.png"></img>
              <img className="d-block mx-auto mt-2" width="80px" src="img/comobtn.png"></img>
            </div>
        </div>

        <div className='d-flex flex-column justify-content-center align-items-center'>
            <img width={"100%"} className='d-block mx-auto' src="img/recepcion.png"/>
          
        </div>

        <div className='d-flex flex-column justify-content-center align-items-center'>
            <img width={"100%"}  className='d-block mx-auto' src="img/manos.png"/>
        </div>

        <div className='d-flex flex-column justify-content-center align-items-center'>
            <img width={"100%"} className='d-block mx-auto' src="img/talieva7.png"/>
        </div>

        <div className='d-flex flex-column justify-content-center align-items-center mt-5'>
            <div className="f-30 text-center bold stix">CONFIRMACIÓN</div>
            <div className="text-center f-16 pop mt-3">
            Para nosotros es sumamente importante <br></br> contar con tu  confirmación de  <br></br> asistencia. Haz clic en el boletito y <br></br> marca tu nombre. ¡Gracias!
            </div>
     
            <img width={"350px"} className='d-block mx-auto mt-4 cursor-pointer' src="img/confirmacion.png" onClick={()=>{
            
              setState({...state, show_invitados:true})
            }}/>
              <div className="f-30 bold stix text-center line-0 mt-3">
              ¡TE ESPERAMOS!
              </div>
              <div className="f-22 bold stix text-center line-0">
            
              Este código es tu pase de entrada.
              </div>
            <Renderif isTrue={true}>
              <div className="qr mt-2">
                <img width={350} className="mt-2" src="img/qr2.png"></img>
                <img width="125px" height="125px" className="qr-code" src={`https://api.qrserver.com/v1/create-qr-code/?size=125x125&data=https://enbodachados.knot.mx/attendance/${id}`}></img>
              </div>

            </Renderif>
        </div>

        <div className='d-flex flex-column justify-content-center align-items-center mt-5 pt-5'>
        <div className='text-center f-30 line-1 stix bold mb-3'>
              Comparte tus fotos <br></br>
              con nosotros <br></br>
            </div>
            <div className="ps-3 mt-4">
              <img width={"80%"} className='d-block mx-auto' src="img/comparte.png"/>
            </div>
            <img width={"50%"} className='d-block mx-auto cursor-pointer' src="img/drive.png" onClick={()=>{
              window.open("https://drive.google.com/drive/folders/15kFdhL8bEZwZ8LgR1pxTqsjed6j_KZ0r?usp=share_link","blanl")
            }}/>
            <div className="d-flex mt-4">

              <div className="cursor-pointer" onClick={()=>{
                window.open("https://www.instagram.com/enbodachados/", "blank")
              }}>
                <FaInstagram size={60}></FaInstagram>
              </div>
              <div className="mx-3"></div>
              <div className="cursor-pointer" onClick={()=>{
                         window.open("https://open.spotify.com/playlist/42IpH6sQKXxPJrPrtbpmtA/", "blank")
              }}>
                <FaSpotify size={60}></FaSpotify>
              </div>
            </div>
        </div>

        <div className="text-center f-16 pop mt-5">
        Tu presencia es el mejor regalo, <br></br> pero si deseas hacernos un presente, <br></br> este puede ser en efectivo o mediante <br></br> transferencia.
            </div>

        <div className='d-flex flex-column justify-content-center align-items-center mt-4'>
            <img width={"85%"} className='d-block mx-auto' src="img/Avion.png"/>
        </div>

        <div className='d-flex flex-column justify-content-center align-items-center mt-5'>
            <div className='text-center bold f-30 stix'>
              Evangelina Ortiz Muñoz <br></br>
              Banamex <br></br>
            </div>
        </div>

        <div className='d-flex flex-column justify-content-center align-items-center bg-cian py-3'>
            <div className='d-flex flex-column'>
              <div className='text-white bold text-center f-20 pop'>Número de tarjeta:</div>
              
              <div className='d-flex justify-content-center align-items-center'>
                <div className='text-white text-center f-18 pop'>5256 7811 2622 9090</div>
                <div className='icon icon--mid cursor-pointer' onClick={()=>{

                  navigator.clipboard.writeText("5256781126229090");
                  setState({...state, copy:true})
                  setTimeout(()=>{
                    setState({...state, copy:false})
                  },2000)
                }}>
                  <MdContentCopy color='white'></MdContentCopy>
                </div>
              </div>
            </div>

            <div className='d-flex flex-column mt-3'>
              <div className='text-white bold text-center f-20 pop'>Clabe:</div>
              
              <div className='d-flex justify-content-center align-items-center'>
                <div className='text-white text-center f-18 pop'>002470901168376837</div>
                <div className='icon icon--mid cursor-pointer' onClick={()=>{
                   navigator.clipboard.writeText("002470901168376837");
                   setState({...state, copy:true})
                   setTimeout(()=>{
                     setState({...state, copy:false})
                   },2000)
                }}>
                  <MdContentCopy color='white'></MdContentCopy>
                </div>
              </div>
            </div>

            <div className='d-flex flex-column mt-3'>
              <div className='text-white bold text-center f-20 pop'>Cuenta:</div>
              
              <div className='d-flex justify-content-center align-items-center'>
                <div className='text-white text-center f-18 pop'>6837683</div>
                <div className='icon icon--mid cursor-pointer' onClick={()=>{
                   navigator.clipboard.writeText("6837683");
                   setState({...state, copy:true})
                   setTimeout(()=>{
                     setState({...state, copy:false})
                   },2000)
                }}>
                  <MdContentCopy color='white'></MdContentCopy>
                </div>
              </div>
            </div>
        </div>

        <div className='d-flex flex-column justify-content-center align-items-center mt-3 pb-5'>
            <img width={"100%"} className='d-block mx-auto' src="img/enbodachados.png"/>
        </div>
        <div className='d-flex flex-column justify-content-center align-items-center pb-5 mb-5'>
            <img width={"120px"} className='d-block mx-auto cursor-pointer' src="img/knotlogo.png"/>
        </div>

        {/* <div className='cursor-pointer' onClick={async ()=>{

          let _add =  await setDoc(doc(db, "invitados", "1"), {
            name: "Adriana",
            ui: "1",
            attendance: true
          });
          console.log(_add)


        }}>ADD</div> */}
    </div>


   <Renderif isTrue={state.confirmed}>
    <div className="atoast">
        <div className="p-3 d-flex justify-content-center align-items-center">
          <div className="icon icon--big">
            <LuHeartHandshake size={32}></LuHeartHandshake>
          </div>
          <div className="f-26 bold">{state.copyText}</div>
        </div>
      </div>
   </Renderif>

    <Renderif isTrue={state.copy}>
      <div className="atoast">
        <div className="p-3 d-flex justify-content-center align-items-center">
          <div className="icon icon--big">
            <MdContentCopy size={24}></MdContentCopy>
          </div>
          <div className="f-20 bold">¡Copiado!</div>
        </div>
      </div>
    </Renderif>
    <Renderif isTrue={state.show_invitados}>
        <div className='edit-modal'  onClick={()=>{
          setState({...state, show_invitados:false})
        }}>
          <div className='edit-modal-body p-3 p-md-5' onClick={(evt)=>{evt.stopPropagation()}}>

            <div className="f-16 pop bold mb-3">Marca la casilla de los asistentes confirmados.</div>

            <div>
              <CheckBox checked={state.invitado?.attendance} text={`${state.invitado?.name}`} onChange={(evt)=>{
                     state.invitado.attendance = evt.value;
                    //  onUpdate();
              }}></CheckBox>
            </div>

            {
              state.invitado?.familia?.map((item,i)=>{
               return (
                <div key={i}>
                  <CheckBox  checked={item.attendance} value={item.ui} text={`${item.name}`} onChange={async (evt)=>{
                

                     state.invitado.familia[i].attendance = evt.value;
                    //  onUpdate();

                    

                  }}></CheckBox>
                </div>
               )
              })
            }
            {
              renderExtras(state.invitado?.extra).map((item,i)=>{
               return (
                <div key={i}>
                  <CheckBox checked={state.invitado?.extraAttendance?.includes(i+1)} text={`Acompañante ${i+1}`} onChange={(evt)=>{

               
                        if(state.invitado.extraAttendance === undefined){
                          state.invitado.extraAttendance = []

                          if(evt.value){
                            state.invitado.extraAttendance.push(i+1)
                          }else{
                            state.invitado.extraAttendance = state.invitado.extraAttendance.filter((el) => el !== (i+1))
                          }
                         


                        }else{


                          if(evt.value){
                            state.invitado.extraAttendance.push(i+1)
                          }else{
                            state.invitado.extraAttendance = state.invitado.extraAttendance.filter((el) => el !== (i+1))
                          }
                        }
                        // onUpdate();
                        
                     
                        

                  }}></CheckBox>
                </div>
               )
              })
            }
            {
              renderKids(state.invitado?.kids).map((item,i)=>{
               return (
                <div key={i}>
                  <CheckBox checked={state.invitado?.kidAttendance?.includes(i+1)} text={`Niño/a ${i+1}`} onChange={(evt)=>{

               
                        if(state.invitado.kidAttendance === undefined){
                          state.invitado.kidAttendance = []

                          if(evt.value){
                            state.invitado.kidAttendance.push(i+1)
                          }else{
                            state.invitado.kidAttendance = state.invitado.kidAttendance.filter((el) => el !== (i+1))
                          }
                         


                        }else{


                          if(evt.value){
                            state.invitado.kidAttendance.push(i+1)
                          }else{
                            state.invitado.kidAttendance = state.invitado.kidAttendance.filter((el) => el !== (i+1))
                          }
                        }
                        // onUpdate();
                        
                     
                        

                  }}></CheckBox>
                </div>
               )
              })
            }
            
            
         
            
            {/* <div className='mt-1'>
              <div>Nombre:</div>
              <input value={state.editing?.name} type='text' className="form-control" onChange={(evt)=>{
                state.editing.name = evt.currentTarget.value;
                setState({...state});
              }} ></input>

            </div>

            <div className='mt-3'>
              <div>Pases Extra:</div>
              <input  value={state.editing?.extra} type='number' className="form-control" onChange={(evt)=>{
                state.editing.extra = parseInt(evt.currentTarget.value);
                setState({...state});
              }}></input>
            </div>

            <div className='mt-3'>
              <div>Niños:</div>
              <input  value={state.editing?.kids} type='number' className="form-control" onChange={(evt)=>{
                state.editing.kids = parseInt(evt.currentTarget.value);
                setState({...state});
              }} ></input>
            </div>

            <div className='mt-3'>
              <div>Asistencia:</div>
              <select className='form-control' value={state.editing?.attendance.toString()} onChange={(evt)=>{
                  state.editing.attendance = evt.currentTarget.value
                  // setState({...state});
              }}>
                <option value="true">SI</option>
                <option value="false">NO</option>
              </select>
            </div> */}

      
          <div className='d-flex'>
            <div className='col'></div>
            <div className='btn bg-green' onClick={()=>{
               onUpdate();
           
            }}>CONFIRMAR</div>
          </div>

          </div>
        </div>
      </Renderif>

    </>

  );
}

export default Invitacion;
