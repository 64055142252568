import logo from './logo.svg';
import './App.css';


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { doc, setDoc } from "firebase/firestore"; 
import { MdContentCopy } from "react-icons/md";
import Invitados from './views/invitados_view';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Invitacion from './views/invitacion_view';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDruyQl5pqrhNGC-MzjEwY7xdQ8iHLzTeE",
  authDomain: "enbodachados-63d1b.firebaseapp.com",
  projectId: "enbodachados-63d1b",
  storageBucket: "enbodachados-63d1b.appspot.com",
  messagingSenderId: "899270868113",
  appId: "1:899270868113:web:7386c38b17177af7f71330"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

function App() {

  return (
    <BrowserRouter>
      <Routes>
          <Route path="/invitados" element={<Invitados></Invitados>}/>
          <Route path="/:id" element={<Invitacion></Invitacion>}/>
      </Routes> 
    </BrowserRouter>
  )

 

  return (

 
  
    <div className='container max'>

        <div className=' vh-100 d-flex justify-content-center align-items-center'>
            <img width={"100%"} className='d-block mx-auto' src="img/talieva.png"/>
        </div>

        <div className=' d-flex flex-column justify-content-center align-items-center'>
            <img width={"100%"} className='d-block mx-auto' src="img/talieva2.png"/>
            <div className='text-center'>
              Después de 9 años juntos, ¡no esta mal, eh! <br></br>
              Nos dimos cuenta de que queremos envejecer <br></br>
              juntos, así que hemos decidido dar el Gran Paso <br></br>
              y continuar el camino con los anillos puestos.
            </div>
        </div>

        <div className=' d-flex flex-column justify-content-center align-items-center mt-5'>
            <img width={"100%"} className='d-block mx-auto' src="img/talieva3.png"/>
            <div className='text-center'>
              Si has recibido esto, es por que para nosotros <br></br>
              eres imprescindible en nuetro día <br></br>
              y que queremos compartirlo contigo. <br></br><br></br>
             Queremos verte reír, comer, saltar, bailar y, si te <br></br>
             empeñas, hasta soltar alguna que otra lagrimilla. <br></br><br></br>
             Esperamos contar contigo.
            </div>
        </div>

        <div className='d-flex flex-column justify-content-center align-items-center mt-5 position-relative border'>
            <img width={"100%"} className='d-block mx-auto' src="img/talieva5.png"/>
            <div className='map-2 cursor-pointer' onClick={()=>{
              window.open('https://www.google.com/maps/place/Parroquia+de+F%C3%A1tima/@19.6954659,-101.1860612,17z/data=!3m1!4b1!4m6!3m5!1s0x842d0c3284963131:0x3b0d62a96b31101f!8m2!3d19.6954659!4d-101.1834809!16s%2Fg%2F1v3gs6yw?entry=ttu','_blank')
            }}></div>
        </div>

        <div className='d-flex flex-column justify-content-center align-items-center mt-5'>
            <img width={"100%"} className='d-block mx-auto' src="img/horario.png"/>
        </div>

        <div className='d-flex flex-column justify-content-center align-items-center mt-5 position-relative'>
            <img width={"100%"} className='d-block mx-auto' src="img/talieva6.png"/>
            <div className='map-1 cursor-pointer' onClick={()=>{
              window.open('https://www.google.com/maps/place/La+finca+Garden/@19.6729855,-101.2212238,17z/data=!3m1!4b1!4m6!3m5!1s0x842d0d154629d6ed:0xcb636e0b734d48fe!8m2!3d19.6729855!4d-101.2212238!16s%2Fg%2F11k46q5kxd?entry=ttu','_blank')
            }}></div>
        </div>

        <div className='d-flex flex-column justify-content-center align-items-center mt-5'>
            <img width={"100%"} className='d-block mx-auto' src="img/recepcion.png"/>
          
        </div>

        <div className='d-flex flex-column justify-content-center align-items-center mt-5 pt-5'>
            <img width={"100%"} className='d-block mx-auto' src="img/talieva7.png"/>
        </div>

        <div className='d-flex flex-column justify-content-center align-items-center mt-5'>
            <img width={"100%"} className='d-block mx-auto' src="img/confirmacion.png"/>
        </div>

        <div className='d-flex flex-column justify-content-center align-items-center mt-5'>
        <div className='text-center'>
              Comparte tus fotos <br></br>
              con nosotros <br></br>
          
            </div>
            <img width={"100%"} className='d-block mx-auto' src="img/comparte.png"/>
        </div>

        <div className='d-flex flex-column justify-content-center align-items-center mt-5'>
            <img width={"100%"} className='d-block mx-auto' src="img/regalo.png"/>
        </div>

        <div className='d-flex flex-column justify-content-center align-items-center mt-5'>
            <div className='text-center bold f-22'>
              Evangelina Ortiz Muñoz <br></br>
              Banamex <br></br>
            </div>
        </div>

        <div className='d-flex flex-column justify-content-center align-items-center bg-cian py-3'>
            <div className='d-flex flex-column'>
              <div className='text-white bold text-center'>Número de tarjeta:</div>
              
              <div className='d-flex justify-content-center align-items-center'>
                <div className='text-white text-center'>5256 7811 2622 9090</div>
                <div className='icon icon--mid cursor-pointer'>
                  <MdContentCopy color='white'></MdContentCopy>
                </div>
              </div>
            </div>

            <div className='d-flex flex-column'>
              <div className='text-white bold text-center'>Clabe:</div>
              
              <div className='d-flex justify-content-center align-items-center'>
                <div className='text-white text-center'>002470901168376837</div>
                <div className='icon icon--mid cursor-pointer'>
                  <MdContentCopy color='white'></MdContentCopy>
                </div>
              </div>
            </div>

            <div className='d-flex flex-column'>
              <div className='text-white bold text-center'>Cuenta:</div>
              
              <div className='d-flex justify-content-center align-items-center'>
                <div className='text-white text-center'>6837683</div>
                <div className='icon icon--mid cursor-pointer'>
                  <MdContentCopy color='white'></MdContentCopy>
                </div>
              </div>
            </div>
        </div>

        <div className='d-flex flex-column justify-content-center align-items-center mt-3 pb-5'>
            <img width={"100%"} className='d-block mx-auto' src="img/enbodachados.png"/>
        </div>

        {/* <div className='cursor-pointer' onClick={async ()=>{

          let _add =  await setDoc(doc(db, "invitados", "1"), {
            name: "Adriana",
            ui: "1",
            attendance: true
          });
          console.log(_add)


        }}>ADD</div> */}
    </div>

  );
}

export default App;
