export const people = [
    { name: "ADRIANA DIAZ SUAREZ", ui: 1, extra: 0, kids: 0, attendance: false },
    { name: "SEBASTIAN CONSTANCIO ARCIGA DIAZ", ui: 2, extra: 0, kids: 0, attendance: false },
    { name: "MARTA ANGELICA SILAVA ARIAS", ui: 3, extra: 0, kids: 0, attendance: false },
    { name: "MARINELI ARCIGA DIAZNELI", ui: 4, extra: 0, kids: 0, attendance: false },
    { name: "EDUARDO", ui: 5, extra: 0, kids: 0, attendance: false },
    { name: "ALMA MIRELLA DIAZ SUAREZ", ui: 6, extra: 0, kids: 0, attendance: false },
    { name: "JAVIER", ui: 7, extra: 0, kids: 0, attendance: false },
    { name: "ALEJANDRO MI E", ui: 8, extra: 0, kids: 0, attendance: false },
    { name: "BERENICE CALDERON DIAZ", ui: 9, extra: 0, kids: 0, attendance: false },
    { name: "HANS ALBERTO CALDERON DIAZ", ui: 10, extra: 0, kids: 0, attendance: false },
    { name: "MARICARMEN CID TAULER", ui: 11, extra: 0, kids: 0, attendance: false },
    { name: "MERIXELLE DIAZ CID", ui: 12, extra: 0, kids: 0, attendance: false },
    { name: "TONNY", ui: 13, extra: 0, kids: 0, attendance: false },
    { name: "SERGIO JULIAN DIAZ CID", ui: 14, extra: 0, kids: 0, attendance: false },
    { name: "KALA ARRIGA", ui: 15, extra: 0, kids: 0, attendance: false },
    { name: "MARIA DOLORES ORETGA", ui: 16, extra: 0, kids: 0, attendance: false },
    { name: "ALEJANDRO DIAZ SUAREZ", ui: 17, extra: 0, kids: 0, attendance: false },
    { name: "ERNESTO ALEJANDRO DIAZ ORTEGA", ui: 18, extra: 0, kids: 0, attendance: false },
    { name: "EREANY", ui: 19, extra: 0, kids: 0, attendance: false },
    { name: "SHAIRA NAYELI DIAZ ORTEGA", ui: 20, extra: 0, kids: 0, attendance: false },
    { name: "HOMERO DIAZ SUAREZ", ui: 21, extra: 0, kids: 0, attendance: false },
    { name: "ANGY", ui: 22, extra: 0, kids: 0, attendance: false },
    { name: "ESCARLET DIAZ", ui: 23, extra: 0, kids: 0, attendance: false },
    { name: "HORTENCIA CORTEZ MEDINA", ui: 24, extra: 0, kids: 0, attendance: false },
    { name: "HOMERO JUNIOR DIAZ CORTEZ", ui: 25, extra: 0, kids: 0, attendance: false },
    { name: "CARLOS OMAR DIAZ CORTEZ", ui: 26, extra: 0, kids: 0, attendance: false },
    { name: "MELIZA ESTEFANI DIAZ CORTEZ", ui: 27, extra: 0, kids: 0, attendance: false },
    { name: "RUTH", ui: 28, extra: 0, kids: 0, attendance: false },
    { name: "DENICE ALEJANDRA DIAZ ORTEGA", ui: 29, extra: 0, kids: 0, attendance: false },
    { name: "MARIA ANTONIETA FERNANDEZ DIAZ", ui: 30, extra: 0, kids: 0, attendance: false },
    { name: "ANGELINES FERNANDES DIAZ", ui: 31, extra: 0, kids: 0, attendance: false },
    { name: "CUAUTEMOC DIAZ SUAREZ", ui: 32, extra: 0, kids: 0, attendance: false },
    { name: "VERONICA", ui: 33, extra: 0, kids: 0, attendance: false },
    { name: "GALILEA DIAZ", ui: 34, extra: 0, kids: 0, attendance: false },
    { name: "JOSE DIAZ SUAREZ", ui: 35, extra: 0, kids: 0, attendance: false },
    { name: "MARIEL DIAZ", ui: 36, extra: 0, kids: 0, attendance: false },
    { name: "LADISLAO ARCIGA GAMIÑO", ui: 37, extra: 0, kids: 0, attendance: false },
    { name: "THALIA ARCIGA", ui: 38, extra: 0, kids: 0, attendance: false },
    { name: "ALEXIS MEDINA", ui: 39, extra: 0, kids: 0, attendance: false },
    { name: "MARIEL ARCIGA", ui: 40, extra: 0, kids: 0, attendance: false },
    { name: "ALAMA ROSA ARCIGA GAMIÑO", ui: 41, extra: 0, kids: 0, attendance: false },
    { name: "PEPE BADA ARCIGA", ui: 42, extra: 0, kids: 0, attendance: false },
    { name: "SUSY MILLAR", ui: 43, extra: 0, kids: 0, attendance: false },
    { name: "MOISES BADA ARCIGA", ui: 44, extra: 0, kids: 0, attendance: false },
    { name: "ROSEL TROLAN", ui: 45, extra: 0, kids: 0, attendance: false },
    { name: "MATT TROLAN", ui: 46, extra: 0, kids: 0, attendance: false },
    { name: "CRIS TROLAN", ui: 47, extra: 0, kids: 0, attendance: false },
    { name: "JESSICA TROLAN", ui: 48, extra: 0, kids: 0, attendance: false },
    { name: "ALMA CLEMENTINA BADA ARCIGA", ui: 49, extra: 0, kids: 0, attendance: false },
    { name: "HANA", ui: 50, extra: 0, kids: 0, attendance: false },
    { name: "RUSELICA", ui: 51, extra: 0, kids: 0, attendance: false },
    { name: "TIO MAIKE", ui: 52, extra: 0, kids: 0, attendance: false },
    { name: "J. DAVID ORTIZ JIMENEZ", ui: 53, extra: 0, kids: 0, attendance: false },
    { name: "MA. DEL REFIJIO MOÑOZ VELAZQUEZ", ui: 54, extra: 0, kids: 0, attendance: false },
    { name: "JOSE CELESTINO ORTIZ MUÑOZ", ui: 55, extra: 0, kids: 0, attendance: false },
    { name: "MARIA DE JESUS ORTIZ MUÑOZ", ui: 56, extra: 0, kids: 0, attendance: false },
    { name: "MARIA JUANA ORTIZ MUÑOZ", ui: 57, extra: 0, kids: 0, attendance: false },
    { name: "RENE OCAÑA RIVERA", ui: 58, extra: 0, kids: 0, attendance: false },
    { name: "DAVID ORTIZ MUÑOZ", ui: 59, extra: 0, kids: 0, attendance: false },
    { name: "MARIA DEL REFUJIO ORTIZ MUÑOZ", ui: 60, extra: 0, kids: 0, attendance: false },
    { name: "MOICES RAMIRO ORTIZ MUÑOZ", ui: 61, extra: 0, kids: 0, attendance: false },
    { name: "THARZZILA AGUIRRE MOLINA", ui: 62, extra: 0, kids: 0, attendance: false },
    { name: "JOSE GUADALUPE ORTIZ MUÑOZ", ui: 63, extra: 0, kids: 0, attendance: false },
    { name: "DIANA LORENA CALVILLO SOZA", ui: 64, extra: 0, kids: 0, attendance: false },
    { name: "GRISELDA Mama apo", ui: 65, extra: 0, kids: 0, attendance: false },
    { name: "CARLOS Papa apo", ui: 66, extra: 0, kids: 0, attendance: false },
    { name: "APO", ui: 67, extra: 0, kids: 0, attendance: false },
    { name: "NEGRO", ui: 68, extra: 0, kids: 0, attendance: false },
    { name: "KATY", ui: 69, extra: 0, kids: 0, attendance: false },
    { name: "RUL", ui: 70, extra: 0, kids: 0, attendance: false },
    { name: "MORELIA", ui: 71, extra: 0, kids: 0, attendance: false },
    { name: "CHINO", ui: 72, extra: 0, kids: 0, attendance: false },
    { name: "CHORTIS", ui: 73, extra: 0, kids: 0, attendance: false },
    { name: "VANE", ui: 74, extra: 0, kids: 0, attendance: false },
    { name: "AXEL", ui: 75, extra: 0, kids: 0, attendance: false },
    { name: "RICHY", ui: 76, extra: 0, kids: 0, attendance: false },
    { name: "CINTIA", ui: 77, extra: 0, kids: 0, attendance: false },
    { name: "BETA", ui: 78, extra: 0, kids: 0, attendance: false },
    { name: "JEAN", ui: 79, extra: 0, kids: 0, attendance: false },
    { name: "CARLOS LOSANO", ui: 80, extra: 0, kids: 0, attendance: false },
    { name: "PALOMA", ui: 81, extra: 0, kids: 0, attendance: false },
    { name: "FANY", ui: 82, extra: 0, kids: 0, attendance: false },
    { name: "RAFA", ui: 83, extra: 0, kids: 0, attendance: false },
    { name: "DIANA", ui: 84, extra: 0, kids: 0, attendance: false },
    { name: "LETY", ui: 85, extra: 0, kids: 0, attendance: false },
    { name: "XOCHIL", ui: 86, extra: 0, kids: 0, attendance: false },
    { name: "DIEGO", ui: 87, extra: 0, kids: 0, attendance: false },
    { name: "FABY", ui: 88, extra: 0, kids: 0, attendance: false },
    { name: "JUNUEN", ui: 89, extra: 0, kids: 0, attendance: false },
    { name: "MARICRUZ", ui: 90, extra: 0, kids: 0, attendance: false },
    { name: "KATINA", ui: 91, extra: 0, kids: 0, attendance: false },
    { name: "ULON", ui: 92, extra: 0, kids: 0, attendance: false },
    { name: "ESPOSA ULON", ui: 93, extra: 0, kids: 0, attendance: false },
    { name: "CHATITA", ui: 94, extra: 0, kids: 0, attendance: false }
    
]