import React, { useEffect, useState } from 'react'
import { people } from '../invidatos'
import { FaEdit } from "react-icons/fa";
import { FaCheckDouble } from "react-icons/fa";
import { MdClose, MdContentCopy } from "react-icons/md";
import { MdPerson } from "react-icons/md";
import { MdPeopleAlt } from "react-icons/md";
import { TbMoodKidFilled } from "react-icons/tb";
import Renderif from '../utils/renderif';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { collection, getFirestore } from "firebase/firestore";
import { doc, setDoc, onSnapshot } from "firebase/firestore"; 
import { LuLink } from 'react-icons/lu';
import { Link } from 'react-router-dom';






// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDruyQl5pqrhNGC-MzjEwY7xdQ8iHLzTeE",
  authDomain: "enbodachados-63d1b.firebaseapp.com",
  projectId: "enbodachados-63d1b",
  storageBucket: "enbodachados-63d1b.appspot.com",
  messagingSenderId: "899270868113",
  appId: "1:899270868113:web:7386c38b17177af7f71330"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);


export default function Invitados() {

  const _invitados = []
  const order = (data) => {
    return data.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }

 



  const [state, setState] = useState({
    invitados: [],
    show_edit:false,
    editing:null,
    copy:false,
    tab:0,
    filter:''
  })

  useEffect( ()=>{
    const unsub = onSnapshot(collection(db, "invitados"), (snapshot) => {
      var invitados = people;
      snapshot.docs.forEach(doc => {
     
  
        invitados = invitados.filter((item)=> item.ui !== doc.data().ui)
        invitados.push(doc.data())
       
        
      });
    
      setState({...state, 
        invitados:order(invitados)
      })
      console.log("AA")
  
  
    });
  },[])

  // useEffect(()=>{
  //   console.log("BB")
  //   setState({...state, invitados:_invitados})
  //   return() => {}
  // },[_invitados])

  const onUpdate = async () =>{
    // state.invitados = state.invitados.filter((item)=>item.ui !== state.editing.ui);
    // state.invitados.push(state.editing);


    // state.editing.attendance = state.editing.attendance === 'true' ? true : false    

    await setDoc(doc(db, "invitados", state.editing.ui.toString() ), state.editing);
    setState ({...state, show_edit:false});



  }
  const totalConfirmed = () => {
    let total = 0;
    total = state.invitados?.filter((item)=>item.attendance === true).length;
    return total;
  }
  const totalExtras = () => {
    let total = 0;
    state.invitados.map((item)=>{
      total = total + item.extra
    })
    return total;

  }
  const totalKids = () => {
    let total = 0;
    state.invitados.map((item)=>{
      total = total + item.kids
    })
    return total;

  }
  const kidsConfirmed = () => {
    let total = 0;
    state.invitados.map((item)=>{
      if(item?.kidAttendance){
        total = total + item?.kidAttendance?.length
      }
     
    })
    return total;
  }
  const extrasConfirmed = () => {
    let total = 0;
    state.invitados.map((item)=>{
      if(item?.extraAttendance){
        total = total + item?.extraAttendance?.length
      }
     
    })
    return total;
  }

  const filter =  (text) => {

    let filter;
    switch (state.tab) {
      case 0:
        filter =  state.invitados;
        break;

      case 1:
       filter = state.invitados.filter((item)=>item.attendance === true);
        break;
      case 2:
        filter = state.invitados.filter((item)=>item.attendance === false);
        break;
      case 3:
        filter = state.invitados.filter((item)=>item.extra > 0);
        break;
      case 4:
        filter = state.invitados.filter((item)=>item.kids > 0);
        break;
     
      default:
        break;
    }

    if(text !== ''){
      filter = filter.filter((item) => item.name.toUpperCase().includes(text.toUpperCase()))
    }
    return filter;
   
  }


  return (
    <>
  
      <div className='container-fluid'>

      <div className='d-flex flex-wrap align-items-center my-2 px-2'>
       
        <div className='mx-1'></div>
        <div className={`pill ${state.tab === 1 ? 'pill--active':''}`} onClick={()=>{
          setState({...state, tab:1})
        }}>
          <div className='f-14 pop'><span className='bold'>Confirmados</span>: <span className='bolder text-success'>{totalConfirmed()}</span></div>
        </div>

  
        <div className='mx-1'></div>
        <div className={`pill ${state.tab === 2 ? 'pill--active':''}`} onClick={()=>{
          console.log("SIN")
          setState({...state, tab:2})
        }}>
          <div className='f-14 pop'><span className='bold'>Sin Confirmar</span>: <span className='bolder text-danger'>{state.invitados.length - totalConfirmed()}</span></div>
        </div>

        <div className='mx-1'></div>
        <div className={`pill ${state.tab === 0 ? 'pill--active':''}`} onClick={()=>{
          setState({...state, tab:0})
        }}>
          <div className='f-14 pop'><span className='bold'>Invitados</span>: <span className='bolder'>{state.invitados.length}</span></div>
        </div>

        <div className='mx-1'></div>
        <div className={`pill ${state.tab === 3 ? 'pill--active':''}`} onClick={()=>{
          setState({...state, tab:3})
        }}>
          <div className='f-14 pop'><span className='bold'>Extras</span>: <span className='bolder text-success'>{extrasConfirmed()}</span> | <span className='bolder'>{totalExtras()}</span></div>
        </div>

        <div className='mx-1'></div>
        <div className={`pill ${state.tab === 4 ? 'pill--active':''}`} onClick={()=>{
          setState({...state, tab:4})
        }}>
          <div className='f-14 pop'><span className='bold'>Niños</span>: <span className='bolder text-success'>{kidsConfirmed()}</span> | <span className='bolder'>{totalKids()}</span></div>
        </div>
        
      
        <div className='mx-1'></div>
        <div className={`pill`} onClick={()=>{
          setState({...state, tab:0})
        }}>
          <div className='f-14 pop'><span className='bold'>Total Invitados</span>: <span className='bolder'>{state.invitados.length + totalExtras() + totalKids() }</span></div>
        </div>

        <div className='mx-1'></div>
        <div className={`pill`} onClick={()=>{
          setState({...state, tab:1})
        }}>
          <div className='f-14 pop'><span className='bold'>Total confirmados</span>: <span className='bolder text-success'>{extrasConfirmed() + totalConfirmed() }</span></div>
        </div>
      

        <div className='col'></div>
        <div className={`pill pill--green`} onClick={()=>{
          // setState({...state, tab:2})
          let ui = state.invitados.length + 1;
          console.log(ui);
       
          let _new = { name: "", ui:ui, extra: 0, kids: 0, attendance: false }

          setState({...state, show_edit:true, editing:_new})
        }}>
          <div className='f-14 px-3 pop text-white'><span className='bold'>AÑADIR </span></div>
        </div>
        
      </div>

        
      <div className='d-flex my-2 px-2'>
        <input className='form-control' value={state.filter} placeholder='Buscar' onChange={(evt)=>{
          setState({...state, filter:evt.currentTarget.value})
        }}></input>
      </div>



  

      <div className='border'>
        <div className='d-flex py-2 px-2'>
          <div className='col'>
            <div className='icon icon--mid cursor-pointer' onClick={()=>{
              setState({...state})
            }}>
              <MdPerson color='#555' size={32}></MdPerson>
            </div>
          </div>
          <div>
            <div className='icon icon--mid'>
              <MdPeopleAlt  color='#555' size={32}></MdPeopleAlt>
            </div>
          </div>
          <div>
            <div className='icon icon--mid'>
              <TbMoodKidFilled  color='#555' size={32}></TbMoodKidFilled>
            </div>
          </div>
          <div>
            <div className='icon icon--mid'>

            </div>
          </div>
          <div className='ps-3'>
            <div className='icon icon--mid'>
            </div>
          </div>
          <div className='ps-3'>
            <div className='icon icon--mid'>
            </div>
          </div>
          
          
        </div>
        {
          filter(state.filter).map((item, i)=>{
          
            return (
              <div key={i} className='invitado-item'>
                <div className='f-10 line-1 pe-1'>{i + 1}.</div>
                <div className='col f-14 bolder line-1 text-gray'>{item.name}  #{item.ui}</div>
                <div className='icon icon--mid bold f-14 line-1'>{item.extra}</div>
                <div className='icon icon--mid bold f-14 line-1'>{item.kids}</div>
                <div className=''>
                    {item?.attendance ? 
                    (<div className='icon icon--mid'>
                      <FaCheckDouble color='green' size={18} />
                    </div> )
                      :
                    (<div className='icon icon--mid'>
                      <FaCheckDouble  color='#CCC' size={18} />
                    </div>)
                    }
                </div>
                <div className='ps-3'>
                    <div className='icon icon--mid cursor-pointer' onClick={async ()=>{
                      try {
                        await setDoc(doc(db, "invitados", item.ui.toString() ), item);
                      } catch (error) {
                          console.log(error)
                      }
                  
                    

                       setState({...state, copy:true, copyName:item.name, copyText:"https://enbodachados.knot.mx/"+item.ui})
                     
                    }}>
                    <MdContentCopy size={20} color='lightblue' />
                    </div>
                </div>
                <div className='ps-3'>
                    <div className='icon icon--mid cursor-pointer' onClick={()=>{
                      setState({...state, show_edit:true, editing:item})
                    }}>
                      <FaEdit size={20
                      } color='lightblue' />
                    </div>
                </div>
        
                
              </div>
            )
          })
        }
      </div>


      </div>
      <Renderif isTrue={state.show_edit}>
        <div className='edit-modal'  onClick={()=>{
          setState({...state, show_edit:false})
        }}>
          <div className='edit-modal-body p-3 p-md-5' onClick={(evt)=>{evt.stopPropagation()}}>
            
            <div className='mt-1'>
              <div>Nombre:</div>
              <input value={state.editing?.name} type='text' className="form-control" onChange={(evt)=>{
                state.editing.name = evt.currentTarget.value;
                setState({...state});
              }} ></input>

            </div>

            <div className='mt-1'>
              <div>Pases Extra:</div>
              <input  value={state.editing?.extra} type='number' className="form-control" onChange={(evt)=>{
                state.editing.extra = parseInt(evt.currentTarget.value);
                setState({...state});
              }}></input>
            </div>

            <div className='mt-1'>
              <div>Niños:</div>
              <input  value={state.editing?.kids} type='number' className="form-control" onChange={(evt)=>{
                state.editing.kids = parseInt(evt.currentTarget.value);
                setState({...state});
              }} ></input>
            </div>

            <div className='mt-1'>
              <div>Asistencia:</div>
              <select className='form-control' value={state.editing?.attendance} onChange={(evt)=>{
                  state.editing.attendance = evt.currentTarget.value
                  setState({...state});
              }}>
                <option value={true}>SI</option>
                <option value={false}>NO</option>
              </select>
            </div>

            <div className='mt-1'>
              <div>Familia:</div>
              <select className='form-control' value="" onChange={(evt)=>{
                console.log(evt.currentTarget.value)
                let _inv = state.invitados.find((item)=> item.ui === parseInt(evt.currentTarget.value))
                if(!state.editing.familia){
                
                  state.editing.familia = []
                  state.editing.familia.push(_inv);
                  
                }else{
          
                  state.editing?.familia.push(_inv);
                }
                setState({...state})
           
              }}>
                  <option value="">Seleccionar</option>
                {
                  state.invitados.map((item, i)=>{
      
                    return (
                      <option key={i} value={item.ui}>{item.name} </option>

                    )
                  })
                }
              </select>
            </div>
            <div className='familia'>
                {
                  state.editing?.familia?.map((item, i)=>{
                   return (
                    <div key={i} className='d-flex align-items-center'>
                       <div className='icon icon--mid cursor-pointer' onClick={()=>{
                        state.editing.familia.shift();
                        setState({...state})
                       }}> 
                        <MdClose color='red'></MdClose>
                      </div>
                      <div className='f-12 bold'>{item.name}</div>
                      {/* <div className='col'></div> */}
                     
                    </div>
                   )
                  })
                }
            </div>

      
          <div className='d-flex'>
            <div className='col'></div>
            <div className='btn bg-green' onClick={()=>{
              onUpdate();
            }}>ACTUALIZAR</div>
          </div>

          </div>
        </div>
      </Renderif>
      <Renderif isTrue={state.copy}>
      <div className="atoast p-1 pb-3">
        <div className='d-flex'>
            <div className='col'></div>
            <div className='px-1'>
              <div className="icon icon--mid cursor-pointer" onClick={()=>{
                setState({...state, copy:false, copyText:'', copyName:''})
              }} >
                <MdClose size={24}></MdClose>
              </div>
            </div>

        </div>
        <div className="p-0 d-flex flex-column justify-content-center align-items-center" >
              <div className='d-flex justify-content-center align-items-center'>
                <div className="icon icon--mid pe-2">
                  <LuLink size={24}></LuLink>
                </div>
                <div className="f-20">{state.copyName}</div>
              </div>


              <div className="f-20 bold">{state.copyText}</div>
        </div>
      </div>
    </Renderif>
    </>
  )
}
